<script setup lang="ts">
const links = [
  { name: 'Právní služby', path: '/pravni-sluzby' },
  { name: 'O nás', path: '/o-nas' },
  { name: 'Náš tým', path: '/nas-tym' },
  { name: 'Blog', path: '/blog' },
  { name: 'Kontakty', path: '/kontakty' },
]

const socials = [
  { icon: 'bxl:facebook-circle', name: 'Facebook', path: 'https://www.facebook.com/advokatutter/' },
  { icon: 'bxl:linkedin-square', name: 'LinkedIn', path: 'https://www.linkedin.com/in/mgr-et-mgr-miloslav-tutter-77027541/' },
  { icon: 'bxl:instagram', name: 'Instagram', path: 'https://www.instagram.com/advokatutter/' },
]
</script>

<template>
  <div id="contact" class="space-y-12 xl:-mt-12">
    <AppFooterContact />

    <footer class="container text-xs tracking-wider text-muted-foreground">
      <div class="grid grid-cols-1 gap-8 pb-12 pt-16 xl:grid-cols-5 xl:gap-20">
        <div class="space-y-6 xl:col-span-2">
          <NuxtLink
            aria-label="Link to AK Tutter homepage"
            class="self-center"
            to="/"
          >
            <NuxtImg
              alt="Logo AK Tutter"
              class="w-[136px] xl:w-[150px]"
              height="43"
              loading="lazy"
              src="/images/logo-dark.svg"
              width="150"
            />
          </NuxtLink>

          <p>Zakládáme si na osobním vztahu s každým klientem. Nabízíme Vám nadstandardní služby při řešení Vašich právních záležitostí.</p>
        </div>

        <div class="space-y-6">
          <IconAndText>
            <template #icon>
              <Icon
                class="text-secondary"
                name="carbon:map"
                size="1.75em"
              />
            </template>

            <template #text>
              <a href="https://mapy.cz/s/nusujororo" target="_blank">
                <p>Ječná 550/1,</p>

                <p>120 00 Praha 2</p>
              </a>
            </template>
          </IconAndText>

          <IconAndText>
            <template #icon>
              <Icon
                class="text-secondary"
                name="carbon:send-alt"
                size="1.75em"
              />
            </template>

            <template #text>
              <p>
                <a href="tel:+420774662237" target="_top">774 66 22 37</a>
              </p>

              <p>
                <a href="mailto:tutter@aktutter.cz" target="_top">tutter@aktutter.cz</a>
              </p>
            </template>
          </IconAndText>
        </div>

        <div>
          <ul class="space-y-3">
            <li
              v-for="social, index in socials"
              :key="index"
              class="flex items-center justify-start gap-2"
            >
              <Icon
                class="text-secondary"
                :name="social.icon"
                size="2em"
              />

              <NuxtLink target="_blank" :to="social.path">
                {{ social.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>

        <div>
          <ul>
            <li
              v-for="link, index in links"
              :key="index"
              class="flex items-center justify-start gap-1 pb-2"
            >
              <Icon
                class="text-secondary"
                name="carbon:chevron-right"
                size="1.75em"
              />

              <NuxtLink :to="link.path">
                {{ link.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>

      <div class="flex flex-col justify-start gap-x-8 gap-y-4 border-t border-gray-200 py-12 xl:flex-row">
        <p>AK Tutter &copy; {{ new Date().getFullYear() }} – All rights reserved.</p>

        <NuxtLink
          class="mr-auto"
          target="_blank"
          to="https://obvio.cz"
        >
          Vytvořilo studio <span class="font-medium">obvio</span>.
        </NuxtLink>

        <NuxtLink class="underline hover:no-underline" to="/souhlas-se-zpracovanim-osobnich-udaju">
          Souhlas se zpracováním osobních údajů
        </NuxtLink>
      </div>

      <p class="pb-12 text-justify text-[09px] tracking-tight">
        Tímto informujeme naše klienty – spotřebitele (tj. osoby nejednající v rámci své podnikatelské činnosti nebo v rámci samostatného výkonu svého povolání) v souladu s § 14 zák. č. 634/1992 Sb., o ochraně spotřebitele, v platném znění, o možnosti požádat Českou advokátní komoru o mimosoudní řešení sporů, jež mohou vzniknout z uzavřených smluv o poskytování právních služeb. Kontakt a další informace lze nalézt na internetových stránkách České advokátní komory www.cak.cz. Tato možnost se nevztahuje na smlouvy o poskytování právních služeb uzavírané s podnikatelskými subjekty.
      </p>
    </footer>
  </div>
</template>
