<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import maplibregl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'

const breakpoints = useBreakpoints(breakpointsTailwind)

const { public: { mapyApiKey } } = useRuntimeConfig()

const mapEl = ref<HTMLDivElement>()

watch([mapEl, breakpoints.active()], ([el]) => {
  if (el) {
    const map = new maplibregl.Map({
      container: el,
      center: [14.4283215, 50.0756164],
      zoom: 15,
      style: {
        version: 8,
        sources: {
          'basic-tiles': {
            type: 'raster',
            url: `https://api.mapy.cz/v1/maptiles/basic/tiles.json?apikey=${mapyApiKey}`,
            tileSize: 256,
          },
        },
        layers: [{
          id: 'tiles',
          type: 'raster',
          source: 'basic-tiles',
        }],
      },
      // attributionControl: false,
      scrollZoom: false, // Zakáže zoomování pomocí kolečka myši
      boxZoom: false, // Zakáže zoomování pomocí obdélníkového výběru
      dragRotate: false, // Zakáže rotaci mapy pomocí myši
      dragPan: false, // Zakáže posouvání mapy pomocí myši
      keyboard: false, // Zakáže ovládání mapy pomocí klávesnice
      doubleClickZoom: false, // Zakáže zoomování pomocí dvojkliku
      touchZoomRotate: false, // Zakáže zoomování a rotaci pomocí dotykových gest
    })

    // const markerElement = document.createElement('div')
    // markerElement.style.backgroundColor = 'red' // Nastavte požadovanou barvu
    // markerElement.style.width = '30px' // Nastavte šířku
    // markerElement.style.height = '30px' // Nastavte výšku
    // markerElement.style.borderRadius = '50%' // Pro kruhový marker
    // markerElement.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.5)' // Přidá trochu stínu

    new maplibregl.Marker(/* { element: markerElement } */)
      .setLngLat([14.4210849, 50.0757834])
      .addTo(map)
  }
})
</script>

<template>
  <div ref="mapEl" class="size-full" />
</template>
