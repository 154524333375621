<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { Loader2 } from 'lucide-vue-next'
import { useForm } from 'vee-validate'
import * as z from 'zod'

const phoneRegex = /^(\+420)? ?\d{3} ?\d{3} ?\d{3}$/

function formatPhoneNumber(phone: string) {
  const cleaned = phone.replace(/[^\d+]/g, '')
  const hasPrefix = cleaned.startsWith('+420')
  const numberPart = hasPrefix ? cleaned.slice(4) : cleaned
  const formattedNumber = numberPart.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')

  return `+420 ${formattedNumber}`
}

const formSchema = toTypedSchema(z.object({
  name: z.string({ required_error: 'Toto pole je povinné' }),
  phone: z.string({ required_error: 'Toto pole je povinné' })
    .regex(phoneRegex, 'Telefonní číslo není ve správném formátu'),
  message: z.string({ required_error: 'Toto pole je povinné' }),
  terms: z.boolean().default(false).refine(val => val === true, {
    message: 'Souhlas s podmínkami je povinný',
  }),
}))

const isFormSubmitting = ref(false)
const isFormSuccessfullySubmitted = ref(false)
const { handleSubmit } = useForm({
  validationSchema: formSchema,
  initialValues: {
    phone: '+420 ',
    terms: false,
  },
})

const onSubmit = handleSubmit(async (values, { resetForm, setFieldError }) => {
  if (isFormSubmitting.value) {
    return
  }

  const { terms: _, ...data } = values

  const formattedPhone = formatPhoneNumber(data.phone)
  data.phone = formattedPhone

  isFormSubmitting.value = true

  try {
    await $fetch('/api/send', {
      method: 'POST',
      body: JSON.stringify(data),
    })

    resetForm()
    isFormSuccessfullySubmitted.value = true
  }
  catch (_) {
    setFieldError('name', 'Něco se pokazilo, zkuste to prosím znovu')
  }
  finally {
    isFormSubmitting.value = false
  }
})
</script>

<template>
  <div class="h-full xl:relative xl:z-0">
    <ClientOnly>
      <div class="hidden h-64 xl:absolute xl:inset-x-0 xl:-bottom-12 xl:top-12 xl:-z-10 xl:block xl:h-full">
        <AppFooterMap />
      </div>
    </ClientOnly>

    <div class="xl:container">
      <form class="[&>*]:dark relative ml-auto bg-primary p-8 xl:w-1/2 xl:p-12" @submit="onSubmit">
        <h2 class="h2 mb-6 text-white">
          Domluvte si bezplatnou právní konzultaci
        </h2>

        <p class="mb-8 text-sm text-muted-foreground">
          Kontaktujte nás pro bezplatnou právní konzultaci a zjistěte, jak můžete využít naše advokátní služby. Jsme připraveni Vám pomoci s právními dotazy, problémy a poradenstvím v různých oblastech práva.
        </p>

        <div class="relative">
          <Transition
            enter-active-class="transition duration-300 ease-out"
            enter-from-class="transform -translate-y-2 opacity-0"
            enter-to-class="transform translate-y-0 opacity-100"
          >
            <div
              v-if="isFormSuccessfullySubmitted"
              class="absolute inset-x-0 mt-4 border-l-8 border-green-500 bg-green-100 p-4 text-green-700"
              role="alert"
            >
              <p class="mb-1 text-xl font-bold">
                Zpráva byla úspěšně odeslána.
              </p>

              <p>
                Děkujeme, že jste nás kontaktovali. Ozveme se Vám nejpozději následující pracovní den.
              </p>
            </div>
          </Transition>

          <div :class="{ 'opacity-0': isFormSuccessfullySubmitted }">
            <div class="mb-6 grid grid-cols-2 gap-6 text-white">
              <div class="col-span-2 xl:col-span-1">
                <AppFormField v-slot="{ componentField, meta: { valid, touched } }" name="name">
                  <AppFormItem>
                    <AppFormLabel>Celé jméno</AppFormLabel>

                    <AppFormControl>
                      <AppInput
                        v-bind="componentField"
                        autocomplete="name"
                        :class="{ 'border-destructive': touched && !valid }"
                        type="text"
                      />
                    </AppFormControl>

                    <AppFormMessage />
                  </AppFormItem>
                </AppFormField>
              </div>

              <div class="col-span-2 xl:col-span-1">
                <AppFormField v-slot="{ componentField, meta: { valid, touched } }" name="phone">
                  <AppFormItem>
                    <AppFormLabel>Telefonní číslo</AppFormLabel>

                    <AppFormControl>
                      <AppInput
                        v-bind="componentField"
                        autocomplete="tel"
                        :class="{ 'border-destructive': touched && !valid }"
                        type="text"
                      />
                    </AppFormControl>

                    <AppFormMessage />
                  </AppFormItem>
                </AppFormField>
              </div>

              <div class="col-span-2">
                <AppFormField v-slot="{ componentField, meta: { valid, touched } }" name="message">
                  <AppFormItem>
                    <AppFormLabel>S čím Vám můžeme pomoci?</AppFormLabel>

                    <AppFormControl>
                      <AppTextarea
                        v-bind="componentField"
                        autocomplete="off"
                        class="h-36 w-full resize-none p-4"
                        :class="{ 'border-destructive': touched && !valid }"
                      />
                    </AppFormControl>

                    <AppFormMessage />
                  </AppFormItem>
                </AppFormField>
              </div>
            </div>

            <div class="mb-6 flex gap-x-2">
              <AppFormField
                v-slot="{ value, handleChange }"
                name="terms"
                type="checkbox"
              >
                <AppFormItem class="flex flex-row items-start gap-x-3 space-y-0">
                  <AppFormControl>
                    <AppCheckbox
                      aria-label="Souhlasím s pravidly pro zpracování osobních údajů"
                      :checked="value"
                      @update:checked="handleChange"
                    />
                  </AppFormControl>

                  <div class="space-y-1 text-sm font-medium leading-none text-white peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    <AppFormLabel for="terms">
                      Souhlasím s pravidly pro

                      <NuxtLink
                        class="inline-block leading-relaxed underline hover:no-underline"
                        target="_blank"
                        to="/souhlas-se-zpracovanim-osobnich-udaju"
                      >
                        zpracování osobních údajů
                      </NuxtLink>.
                    </AppFormLabel>

                    <AppFormMessage />
                  </div>
                </AppFormItem>
              </AppFormField>
            </div>

            <AppButton
              :disabled="isFormSubmitting"
              size="lg"
              type="submit"
              variant="secondary"
            >
              <template v-if="isFormSubmitting">
                <Loader2 class="mr-2 size-4 animate-spin" />

                Odesílání
              </template>

              <template v-else>
                Odeslat
              </template>
            </AppButton>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
